import API from "@/services/api";

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    current_role: "",
  },
  getters: {
    getCurrentRole(state) {
      return state.current_role;
    },
    getUserRoles(state) {
      return state.user.roles.data;
    },
    getCurrentUserId(state) {
      return state.user.id;
    },
    hasRole: (state) => (role) => {
      const userRoles = state.user.roles.data.map((role) => role.id);
      return userRoles.includes(role);
    },
  },
  mutations: {
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setCurrentRole(state, value) {
      state.current_role = value;
    },
  },
  actions: {
    login({ commit }) {
      commit("setAuthenticated", true);
    },
    logout({ commit }) {
      commit("setAuthenticated", false);
      commit("setUser", {});
      commit("setCurrentRole", "");
    },
    async setUser({ commit, dispatch }) {
      return API.getCurrentProfile()
        .then(async ({ data }) => {
          const updatedUser = await dispatch("setUserAbilities", {
            userData: data.data,
          });
          commit("setUser", updatedUser);
          commit("setCurrentRole", data.data.roles.data[0].id);
        })
        .catch(() => {
          commit("setAuthenticated", false);
          commit("setUser", {});
        })
        .finally(() => {});
    },
    setUserAbilities(context, { userData }) {
      if (!userData.permissions || !userData.permissions.data) {
        userData.permissions = { data: [] };
        if (userData.roles && userData.roles.data) {
          userData.roles.data.forEach((role) => {
            if (role.permission && role.permission.data) {
              role.permission.data.forEach((perm) => {
                let formattedPermission = perm.name;
                if (!perm.name.includes(".")) {
                  const words = perm.name.split(" ");
                  const action = words[0];
                  const resource = words.slice(1).join("_").replace(/^_/, "");
                  formattedPermission = `${resource}.${action}`;
                }
                userData.permissions.data.push({
                  id: perm.id,
                  name: formattedPermission,
                });
              });
            }
          });
        }
      }

      let abilities = API.formatUserPermissionsToCaslAbilities(
        userData.permissions.data
      );

      userData.abilities = abilities;
      return userData;
    },
  },
};
