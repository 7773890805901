import axios from "axios";
import Cookies from "js-cookie";
const httpApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});
// Add a request interceptor
httpApi.interceptors.request.use(
  function (config) {
    const token = Cookies.get("XSRF-TOKEN");
    if (token) {
      config.headers["X-XSRF-TOKEN"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export { httpApi };
