import Swal from "sweetalert2/dist/sweetalert2";
export function showMessage(message, icon, timer, redirect, html) {
  if (html !== "html") {
    if (!icon) {
      icon = "success";
    }
    if (icon === "error") {
      icon = { name: "fa-exclamation-triangle", class: "text-danger" };
    }
    if (icon === "success") {
      icon = { name: "fa-check-circle", class: "text-success" };
    }
    message =
      '<i class="fas ' +
      icon.name +
      " text-md " +
      icon.class +
      '"></i> <span>' +
      message +
      "</span>";
  }
  if (!timer) {
    timer = 5000;
  }
  const options = {
    toast: true,
    html: message,
    timer: timer,
    position: "bottom-end",
    showConfirmButton: false,
    timerProgressBar: true,
    width: "22em",
    padding: "0.5em 0.8em",
    customClass: {
      container: "swal2-small h-auto",
    },
  };
  if (redirect) {
    options.willClose = redirect;
  }
  Swal.fire(options);
}
export async function showConfirmation(options, confirmAction) {
  const defaultOptions = {
    title: "Are you sure?",
    confirmButtonText: "Yes, proceed",
    cancelButtonText: "No, I changed my mind",
    showCancelButton: true,
    reverseButtons: true,
    customClass: {
      confirmButton: "btn bg-gradient-danger btn-sm ms-2",
      cancelButton: "btn bg-gradient-success btn-sm",
    },
    buttonsStyling: false,
  };
  const result = await Swal.fire({ ...defaultOptions, ...options });
  if (result.isConfirmed && confirmAction) {
    return await confirmAction();
  }

  return result;
}