<template>
  <router-link class="nav-link" :to="to">
    <div
      class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="textClass + ($store.state.isRTL ? ' me-1' : ' ms-1')"
      >{{ navText }}</span
    >
  </router-link>

  <div>
    <slot name="list"></slot>
  </div>
</template>
<script>
export default {
  name: "Sidenav",
  props: {
    navText: {
      type: String,
      required: true,
    },
    to: {
      type: [Object, String],
      required: true,
    },
    textClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
